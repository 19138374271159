import React from 'react'
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import "./contactForm.css"

const urlEncode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const ContactForm = (props) => {
  const {translations: t} = props;
    return (
      <>
        <Formik
          initialValues={{
            name: "",
            email: "",
            message: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, t.WriteMorePlease)
              .required(t.Required),
            message: Yup.string()
              .min(5, t.WriteMorePlease)
              .required("Required"),
            email: Yup.string()
              .email( t.InvalidEmailAddresss)
              .required("Required"),
          })}
          onSubmit={(values, rest) => {
            console.log(rest);
            
            fetch("/", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: urlEncode({ "form-name": "ContactForm", ...values })
            })
            .then(() => {
              rest.setStatus({message: t.Sent});
              setTimeout(() => {
                rest.resetForm();
              }, 2000);
            })
            .catch(error => {
              console.log("Yeah, that didn't work :(");
            }).finally(() => {
              setTimeout(() => {
                rest.setSubmitting(false);
                rest.setStatus({message: null});
              }, 2000);
            });
          }}
        >
          {(props) => (
          <Form name="ContactForm" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
            <label className="contactformLabel" htmlFor="name">{t.Name}:</label>
            <Field className="contactformInput" name="name" type="text" />
            <span className="contactformErrorField" >
              <ErrorMessage name="name" />
            </span>

          <label className="contactformLabel" htmlFor="email">{t.EmailAddress}:</label>
            <Field className="contactformInput" name="email" type="email" />
            <span className="contactformErrorField" >
              <ErrorMessage name="email" />
            </span>

            <label className="contactformLabel" htmlFor="message">{t.Message}:</label>
            <Field className="contactformInput" as="textarea" name="message" type="text" />
            <span className="contactformErrorField" >
              <ErrorMessage name="message" />
            </span>

            <button type="submit" disabled={!(props.isValid && props.dirty) || props.isSubmitting }>{props.isSubmitting ? t.Sending : t.Send}</button>
            {props.status && props.status && props.status.message && (
              <span className="contactformStatusMessage">{props.status.message}</span>
            )}
          </Form>
          )}
        </Formik>
      </>
    );
  };

export default ContactForm;